<template>
    <v-row no-gutters class="d-block">
        <v-col cols="12" class="">
            <v-card>

                <v-card-text>
                    <v-form ref="form" v-model="valid">
                        <v-row>
                            <v-col cols="12" sm="6" md="6" lg="6" xl="6">

                                <v-text-field
                                    v-model="usuarioModel.usuario"
                                    :label="usuarioForm.usuario.label"
                                    :hint="usuarioForm.usuario.hint"
                                    persistent-hint
                                    :placeholder="usuarioForm.usuario.placeholder"
                                    required
                                    prepend-icon="account_circle"
                                    :rules="usuarioForm.usuario.rules"
                                />

                            </v-col>

                            <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                                <v-text-field
                                    :type="hidePassword ? 'password' : 'text'"
                                    v-model="usuarioModel.password"
                                    :label="usuarioForm.password.label"
                                    :placeholder="usuarioForm.password.placeholder"
                                    :append-icon="hidePassword ? 'visibility_off' : 'visibility'"
                                    prepend-icon="vpn_key"
                                    required
                                    :rules="usuarioForm.password.rules"
                                    @click:append="hidePassword = !hidePassword"/>

                                <v-text-field
                                    :type="hidePassword ? 'password' : 'text'"
                                    v-model="usuarioModel.password_confirmar"
                                    :label="usuarioForm.password_confirmar.label"
                                    :placeholder="usuarioForm.password_confirmar.placeholder"
                                    :append-icon="hidePassword ? 'visibility_off' : 'visibility'"
                                    prepend-icon="vpn_key"
                                    required
                                    :rules="usuarioForm.password_confirmar.rules"
                                    @click:append="hidePassword = !hidePassword"/>
                            </v-col>

                        </v-row>

                    </v-form>
                </v-card-text>
                <v-divider class="mt-1"></v-divider>
                <v-card-actions class="justify-center py-3">
                    <v-btn outlined @click="regresar" small>
                        <v-icon>arrow_back</v-icon>
                        Regresar
                    </v-btn>

                    <v-btn outlined color="warning" @click="reset" small>
                        <v-icon>restart_alt</v-icon>
                        Limpiar
                    </v-btn>

                    <v-btn color="success" :loading="loading" @click="saveUsuario" small>
                        <v-icon>save</v-icon>
                        Guardar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>

export default {
    name: "ParticipantesAdministradoresAccesos",
    props: {
        // Si es requerido falso, que sea Default Vacio
        getParams: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            valid: true,
            loading: false,
            hidePassword: true,
            usuarioModel: {
                persona_id: this.getParams.persona_id,
                usuario_id: null,
                usuario: null,
                password: null,
                password_confirmar: null,
            },
            minCarateres: 6,
            usuarioForm: {
                usuario: {
                    label: 'Nick de Usuario',
                    placeholder: 'Nick de Usuario',
                    hint: "Recomendamos no cambiar el Nick",
                    rules: [
                        (v) => !!v || 'Campo requerido',
                        (v) => !!v && v.length >= this.minCarateres || `El Nick debe tener más de ${this.minCarateres} caracteres.`
                    ],
                },
                password: {
                    label: 'Contraseña',
                    placeholder: 'Contraseña',
                    rules: [
                        (v) => !!v || 'Campo requerido',
                        (v) => !!v && v.length >= this.minCarateres || `La contraseña debe tener más de ${this.minCarateres} caracteres.`,
                        (v) => /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).*$/.test(v) || `La contraseña debe tener letras mayúsculas, minúsculas, símbolos y números.`,
                    ],
                },
                password_confirmar: {
                    label: 'Confirmar la Contraseña',
                    placeholder: 'Confirmar la Contraseña',
                    rules: [
                        (v) => !!v || 'Campo requerido',
                        (v) => !!v && v.length >= this.minCarateres || `La contraseña debe tener más de ${this.minCarateres} caracteres.`,
                        (v) => !!v && v == this.usuarioModel.password || "Las contraseñas deben coincidir."
                    ],
                },
            },
        }
    },
    computed: {
        // DO some
    },
    methods: {
        regresar() {
            this.$router.go(-1)
        },
        reset() {
            // Reset los valores del usuario
            this.$refs.form.reset();
            this.getUsuarioCreate();
        },
        getUsuarioCreate() {
            this.loading = true;

            // Get data;
            let params = {persona_id: this.getParams.persona_id}

            window.axios.get('/personas/accesos', {params}).then(response => {
                if (response.data.success) {

                    this.accesos = response.data.data.accesos;
                    // Si hay accesos
                    if (this.accesos.length) {
                        let acceso = this.accesos[0];
                        this.usuarioModel.usuario = acceso.usuario;
                        this.usuarioModel.usuario_id = acceso.usuario_id;
                    }

                } else if (response.data.success === false) {
                    this.$toast.error(response.data.message);
                }
            })
                .catch(error => {
                    console.log(error)
                    this.$toast.error("No se pudo obtener los datos para guardar.");
                })
                .finally(() => {
                    // Do some
                    this.loading = false;
                })
        },
        saveUsuario() {
            // Validar
            if (this.$refs.form.validate()) {
                this.loading = true;
                let url = (Number(this.usuarioModel.usuario_id)) ? '/usuarios/update' : '/usuarios/store';

                window.axios.post(url, this.usuarioModel).then(response => {
                    if (response.data.success) {
                        this.$toast.success(response.data.message);

                        // Reset y obtener los valores
                        this.reset();

                    } else if (response.data.success === false) {
                        this.$toast.error(response.data.message);
                    }
                })
                    .catch(error => {
                        console.log(error)
                        this.$toast.error("No se pudo guardar");
                    })
                    .finally(() => {
                        // Do some
                        this.loading = false;
                    })
            } else {
                this.$toast.warning("Complete toda la información requerida.");
            }

        },

    },
    created() {
        this.getUsuarioCreate();
    }
}
</script>

<style>

</style>
